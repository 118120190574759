import { API_URL } from "@/const";
import axios from "axios";

export default {
  methods: {
    // cs202201
    /**
     * CSVフォーマット一覧取得API
     * /manifest/csvformat
     * @return {Promise<AxiosResponse<any>>}
     */
    getCSVFormatListApi() {
      return axios
        .get(API_URL.MANIFEST.CSV_FORMAT)
        .then((res) => {
          this.csvformatList = res.data.csvformatList;
        })
        .catch((err) => {
          console.log(err);
          this.csvformatList.splice(0);
        });
    },
    /**
     * CSVフォーマット削除API
     * /manifest/csvformat/{id}
     * @return {Promise<AxiosResponse<any>>}
     */
    patchCSVFormatDeleteApi(id) {
      return axios
        .patch(`${API_URL.MANIFEST.CSV_FORMAT}/${id}`, {
          isActive: false,
        })
        .then(() => {
          this.getCSVFormatListApi();
        })
        .catch((err) => {
          this.modalErrMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    postManifestCsvupload(file) {
      const params = new FormData();
      params.append("file", file);
      return axios
        .post(API_URL.MANIFEST.CSV_UPLOAD, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    postManifestCsvregister(file) {
      const params = new FormData();
      params.append("file", file);
      return axios
        .post(API_URL.MANIFEST.CSV_REGISTER, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
  },
};
