<template>
  <div class="c-modal xlg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 v-if="csvUploadState === CsvUploadState.Select" class="c-lead-s">CSVアップロード</h5>
        <h5 v-if="csvUploadState === CsvUploadState.Cost_Label" class="c-lead-s">CSV実績アップロード(処理費用、ノート)</h5>
        <h5 v-if="csvUploadState === CsvUploadState.OTHER" class="c-lead-s">CSV実績アップロード(有価、一廃、専ら、紙の全体)</h5>
        <span
          class="c-modal__head__close"
          :class="{ disabled: csvUploadProcessing }"
          @click="hideCsvUploadModal"
        ></span>
      </div>
      <div class="c-modal__body">
        <ErrorMessage :errMsgs="csvUploadModalErrMsgs" />
        <div class="u-txt-red" v-text="csvUploadProcessMessage"></div>
        <template v-if="csvUploadState === CsvUploadState.Select">
          <div style="margin-right: auto; margin-left: auto; max-width: 30rem; ">
            <div
              class="c-btn primary small"
              @click="changeCsvUploadCostLabel"
              style="margin-bottom: 5rem; width: 100%; max-width: initial;"
            >
              CSV実績アップロード(処理費用、ノート)
            </div>
            <div
              class="c-btn primary small"
              @click="changeCsvUploadOther"
              style="width: 100%; max-width: initial;"
            >
              CSV実績アップロード(有価、一廃、専ら、紙の全体)
            </div>
          </div>
        </template>
        <template v-if="csvUploadState === CsvUploadState.Cost_Label">
          <Content 
            ref="costLabelContentComponent"
            v-bind:onClickUploadButton="onClickUploadCsv"
            v-bind:initErrorMessage="initCsvUploadModalErrorMessage"
            :isUploadingCSV.sync="csvUploadProcessing"
          />
        </template>
        <template v-if="csvUploadState === CsvUploadState.OTHER">
          <Content 
            ref="otherContentComponent"
            v-bind:onClickUploadButton="onClickUploadRegisterCsv"
            v-bind:initErrorMessage="initCsvUploadModalErrorMessage"
            :isUploadingCSV.sync="csvUploadProcessing"
          />
        </template>
      </div>
      <div class="c-modal__foot">
        <div
          class="c-btn secondary small"
          :class="{ disabled: csvUploadProcessing }"
          @click="hideCsvUploadModal"
        >
          閉じる
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Content from "@/components/parts/CsvUploadModalContent";
import { CSV_UPLOAD_MESSAGE } from "@/const/message";

const CsvUploadState = {
  Select: 1,
  Cost_Label: 2,
  OTHER: 3,
};

export default {
  components: {Content},
  props: {
    onClickCloseButton: {
      type: Function,
    },
    onClickUploadButton: {
      type: Function,
    },
    onClickUploadRegisterCsvButton: {
      type: Function,
    },
  },
  data() {
    return {
      csvUploadModalErrMsgs: [],
      CsvUploadState: CsvUploadState,
      csvUploadState: CsvUploadState.Select,
      csvUploadProcessing: false,
      csvUploadProcessMessage: ""
    }
  },
  methods: {
    resetMessage() {
      this.csvUploadProcessMessage = ""
    },
    hideCsvUploadModal() {
      this.csvUploadState = CsvUploadState.Select;
      this.initCsvUploadModalErrorMessage();
      this.onClickCloseButton();
      if (this.$refs.costLabelContentComponent) {
        this.$refs.costLabelContentComponent.deleteSelectedCsv();
      }
      if (this.$refs.otherContentComponent) {
        this.$refs.otherContentComponent.deleteSelectedCsv();
      }
    },

    initCsvUploadModalErrorMessage() {
      this.csvUploadModalErrMsgs = [];
    },
    changeCsvUploadCostLabel() {
      this.csvUploadState = CsvUploadState.Cost_Label;
    },
    changeCsvUploadOther() {
      this.csvUploadState = CsvUploadState.OTHER;
    },
    onClickUploadCsv(selectedCsvFile) {
      return this.uploadCsv(this.onClickUploadButton)(selectedCsvFile);
    },
    onClickUploadRegisterCsv(selectedCsvFile) {
      return this.uploadCsv(this.onClickUploadRegisterCsvButton)(selectedCsvFile);
    },
    uploadCsv(api) {
      return (selectedCsvFile) => {
        this.csvUploadProcessMessage = CSV_UPLOAD_MESSAGE.PROCESS.replace("<file_name>", selectedCsvFile.name)
        this.initCsvUploadModalErrorMessage();
        return api(selectedCsvFile)
          .then( () => {
            this.resetMessage()
          })
          .catch((err) => {
            this.resetMessage()
            if (err && err.response && err.response.data && err.response.data.message) {
              this.csvUploadModalErrMsgs = [err.response.data.message];
            } else if (err && err.response && err.response.status && err.response.status === 500) {
              this.csvUploadModalErrMsgs = ["想定外のエラーが発生しました"];
            } else {
              this.csvUploadProcessMessage = CSV_UPLOAD_MESSAGE.TIMEOUT.replace("<file_name>", selectedCsvFile.name)
            }
          });
      }
    },
  }
}
</script>

