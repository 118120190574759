<template>
  <div class="uploadModal">
    <div>
      <template v-if="!selectedCsvFile">
        <input id="csv" accept=".csv" type="file" @change="(e) => onSelectCsvFile(e)" />
        <label
          for="csv"
          class="c-btn primary small"
        >
          CSVファイル選択
        </label>
      </template>
      <template v-else>
        <label
          class="c-btn primary small"
          :class="{ disabled: csvUploadProcessing }"
          @click="onClickUploadCsv"
        >
          アップロード
        </label>
      </template>
    </div>
    <div class="c-inputWrap uploadModal__file">
      <div class="c-inputWrap__label uploadModal__label">
        <label>読み込んだCSVファイル</label>
      </div>
      <div class="c-inputWrap__items">
        <div class="c-inputWrap__items__cols center sm-mgn">
          <template v-if="!selectedCsvFile">
            <label class="c-entrustText__text">
              {{ '選択されていません' }}
            </label>
          </template>
          <template v-else>
            <a class="c-entrustText__text">
              {{ selectedCsvFile.name }}
            </a>
            <div @click="deleteSelectedCsv()" class="c-btn secondary delete sm" :class="{ disabled: csvUploadProcessing }">
              削除
            </div>
          </template>
        </div>
      </div>
    </div>
    <ul class="uploadModal__caution">
      <li>
        ※CSVに使用できる文字は Shift_JIS に準じます。特定の記号やJIS第1、第2水準以外の漢字、環境依存文字は文字化け（? と表記）しますのでご注意ください。
      </li>
      <li>
        ※CSVをExcelから作成した際に12桁以上の数値を扱う場合は指数表示（例：1.23457E+11）となります。このままアップロードすると数字ではなく文字列として扱われるため注意して下さい。
        <br/>（大きな数値を扱う場合は CSVアップロードからの更新ではなく画面上から更新するか、CSVの対象セルを表示形式「文字列」に設定して下さい。）
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    onClickUploadButton: {
      type: Function,
    },
    initErrorMessage: {
      type: Function,
    },
  },
  data() {
    return {
      selectedCsvFile: null,
      csvUploadProcessing: false,
    }
  },
  methods: {
    onClickUploadCsv() {
      this.csvUploadProcessing = true;
      this.$emit("update:isUploadingCSV", this.csvUploadProcessing);
      this.onClickUploadButton(this.selectedCsvFile)
        .then(() => {
          this.csvUploadProcessing = false;
          this.$emit("update:isUploadingCSV", this.csvUploadProcessing);
        });
    },

    onSelectCsvFile(e) {
      this.initErrorMessage();
      this.selectedCsvFile = e.target.files[0];
    },

    deleteSelectedCsv() {
      console.debug('deleteSelectedCsv');
      this.initErrorMessage();
      this.selectedCsvFile = null;
    },

  }
}
</script>

<style scoped lang="scss">
.uploadModal {
  &__file {
    margin-top: 0.8rem; 
    align-items: center;
  }
  &__label {
    flex-basis: unset;
    margin-right: 4rem;
  }

  &__caution {
    font-size: 1.2rem;
    margin-top: 3rem;
  }
}

</style>