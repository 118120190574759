<template>
  <div>
    <transition name="fade">
      <div v-if="isOpenCSVformatModal" class="c-modal lg">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__head"><h5 class="c-lead-s">CSVダウンロード</h5><span @click="updateCSVModal(false)" class="c-modal__head__close"></span></div>
          <div class="c-modal__body">
            <div>
              <ErrorMessage :errMsgs="modalErrMsgs" />
              <ErrorMessage :errMsgs="modalDownloadLimitErrMsgs" />
              <div>ダウンロードするCSVのフォーマットを選択してください。</div>
              <div class="l-flex cols l-item">
                <div v-if="isAdmin" @click="newCSVFormatHandler" class="c-btn secondary mini" style="margin-left: auto; width: 16rem;"><div class="c-icn add"></div>フォーマット作成</div>
              </div>

              <div class="c-csv_format__list">
                <template v-if="csvformatList.length">
                  <template v-for="item of csvformatList">
                    <div :key="'csvformatlist-'+item.csvformatId" class="c-csv_format__list_item">
                      <div class="c-radio">
                        <input type="radio" name="approve" :id="'csv_format_'+item.csvformatId" :value="item.csvformatId" v-model="checkedCSVformatID" />
                        <label class="c-radio__label" :for="'csv_format_'+item.csvformatId">
                          <span class="c-radio__box"></span>{{ item.csvformatName }}
                        </label>
                      </div>
                      <div v-if="isAdmin && item.isEdit"  class="c-btn_wrapper">
                        <button @click="duplicateCSVFormatHandler(item.csvformatId)" class="c-btn-icon duplicate">複製</button>
                        <button @click="editCSVFormatHandler(item.csvformatId)" class="c-btn-icon edit">編集</button>
                        <button @click="deleteCSVFormatConfirmHandler(item.csvformatId)" class="c-btn-icon delete">削除</button>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
              <div class="u-txt-center l-area-t" v-if="isDownloading">ダウンロードを行なっています。少々お待ちください。</div>
            </div>
            <div class="c-modal__foot">
              <div @click="downloadCSVHandler" class="c-btn primary small" :class="{ disabled: isDownloading || modalDownloadLimitErrMsgs.length > 0 }">ダウンロード</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="isOpenDeleteConfirmModal" class="c-modal">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__head"><h5 class="c-lead-s">削除確認</h5><span @click="updateDeleteConfirmModal(false)" class="c-modal__head__close"></span></div>
          <div class="c-modal__body">
            <div>
              <div>本当に削除しますか？</div>
            </div>
            <div class="c-modal__foot">
              <div @click="updateDeleteConfirmModal(false)" class="c-btn secondary mini back">戻る</div>
              <div @click="deleteCSVFormat()" class="c-btn primary mini" :class="{disabled: isCSVDeleteLock}">OK</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import callApi from "../mixins/callApi";
import {MANIFEST_DOWNLOAD_LIMIT_VALUE} from "@/const";

export default {
  components: {},
  props: {
    paginationParams: {
      type: Object,
    },
  },
  mixins: [callApi],
  data() {
    return {
      isAdmin: this.$store.getters["userInfo/isAdmin"],
      modalErrMsgs: [],
      modalDownloadLimitErrMsgs: [],
      /**
       * CSVフォーマット選択モーダル展開状態
       */
      isOpenCSVformatModal: false,
      /**
       * CSVフォーマット削除確認モーダル展開状態
       */
      isOpenDeleteConfirmModal: false,
      /**
       * CSVフォーマット一覧データ
       */
      csvformatList: [],
      /**
       * 選択中のCSVフォーマット。すべて:all
       */
      checkedCSVformatID: 1,
      /**
       * 削除対象のCSVフォーマットID。確認モーダルを挟むため格納用として
       */
      deleteCSVformatID: 0,
      /**
       * CSVフォーマット削除API通信中かどうか
       */
      isCSVDeleteLock: false,
      /**
       * CSVダウンロード中かどうか
       */
      isDownloading: false,
    };
  },
  methods: {
    // cs202201：CSVフォーマット関連
    /**
     * CSVダウンロードボタン押下時。CSVフォーマット選択モーダル展開状態
     * @param {boolean} flag
     */
    updateCSVModal(flag) {
      this.isOpenCSVformatModal = flag;
    },
    /**
     * CSVフォーマット削除ボタン押下時。削除確認モーダル展開状態
     * @param {boolean} flag
     */
    updateDeleteConfirmModal(flag) {
      this.isOpenDeleteConfirmModal = flag;
    },
    /**
     * CSVフォーマット新規作成ボタン押下時
     */
    newCSVFormatHandler() {
      this.$router.push(`/manifest/csvformat/input?from=${encodeURIComponent(this.$route.path)}`);
    },
    /**
     * CSVフォーマット編集ボタン押下時
     * @param {number} id
     */
    editCSVFormatHandler(id) {
      this.$router.push(`/manifest/csvformat/edit/${id}?from=${encodeURIComponent(this.$route.path)}`);
    },
    /**
     * CSVフォーマット複製ボタン押下時
     * @param {number} id
     */
    duplicateCSVFormatHandler(id) {
      this.$router.push(`/manifest/csvformat/duplicate/${id}?from=${encodeURIComponent(this.$route.path)}`);
    },
    /**
     * CSVフォーマット削除ボタン押下時。削除確認モーダルを展開
     * @param {number} id
     */
    deleteCSVFormatConfirmHandler(id) {
      this.deleteCSVformatID = id;
      this.updateDeleteConfirmModal(true);
    },
    /**
     * CSVフォーマット削除処理
     */
    deleteCSVFormat() {
      this.modalErrMsgs.splice(0);
      this.isCSVDeleteLock = true;
      this.patchCSVFormatDeleteApi(this.deleteCSVformatID)
      .finally(() => {
        this.updateDeleteConfirmModal(false);
        this.isCSVDeleteLock = false;
      }); // callApi.js
    },
    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler() {
      this.isDownloading = true;
      this.$emit("downloadCSVHandler", this.checkedCSVformatID);
    },
    finishedDownloadCSVHandler() {
      this.isDownloading = false;
    },
  },
  created() {
    this.getCSVFormatListApi(); // callApi.js
  },

  watch: {
    // マニフェストダウンロード件数制限をチェック
    paginationParams: {
      handler() {
        if (this.paginationParams && this.paginationParams.total) {
          if (this.paginationParams.total >= MANIFEST_DOWNLOAD_LIMIT_VALUE) {
            this.modalDownloadLimitErrMsgs.push("2万件以上はCSVダウンロードできません。検索条件を見直してください。");
          } else {
            this.modalDownloadLimitErrMsgs = [];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.c-csv_format {
  &__list {
    max-height: 430px;
    border: 1px solid #DCE7FA;
    border-radius: 8px;
    overflow-y: auto;
    margin-top: 20px;
  }
  &__list_item {
    + .c-csv_format__list_item {
      border-top: 1px solid #CCD0D7;
    }
    display: flex;
    padding: 12px;

    input {
      flex-shrink: 0;
    }
    .c-btn_wrapper {
      display: flex;
      margin-left: auto;
      .c-btn-icon {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1rem;
        appearance: none;
        display: block;
        border: 1px solid #124FB3;
        border-radius: 2px;
        background: white;
        text-indent: -100vw;
        overflow: hidden;
        transition: .2s;
        &:hover {
          background: #124FB3;
        }
        &.duplicate {
          background: {
            image: url('/v_resources/assets/img/icon/duplicate_blue.svg');
            repeat: no-repeat;
            position: center;
          }
          &:hover {
            background: {
              image: url('/v_resources/assets/img/icon/duplicate_white.svg');
              repeat: no-repeat;
              position: center;
            }
          }
        }
        &.edit {
          background: {
            image: url('/v_resources/assets/img/icon/edit_blue.svg');
            repeat: no-repeat;
            position: center;
          }
          &:hover {
            background: {
              image: url('/v_resources/assets/img/icon/edit_white.svg');
              repeat: no-repeat;
              position: center;
            }
          }
        }
        &.delete {
          background: {
            image: url('/v_resources/assets/img/icon/cross_blue.svg');
            repeat: no-repeat;
            position: center;
          }
          &:hover {
            background: {
              image: url('/v_resources/assets/img/icon/cross_white.svg');
              repeat: no-repeat;
              position: center;
            }
          }
        }
      }
    }
  }
}
.c-icn {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.8rem;
  display: inline-flex;
  &.add {
    background: {
      image: url('/v_resources/assets/img/icon/plus_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/plus_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }
}
</style>