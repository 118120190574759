<template>
  <div>
    <div class="l-container">
      <div class="c-tabs lg l-area">
        <div class="c-tabs__each" :class="{ active: !isNoDateShow }" @click="switchTab">
          引き渡し日入力済み
        </div>
        <div class="c-tabs__each" :class="{ active: isNoDateShow }" @click="switchTab">
          引き渡し日未入力
        </div>
      </div>
    </div>
    <div class="c-searchMenu__wrap">
      <div v-if="isDisplaySearch" class="c-searchMenu">
        <div class="l-flex">
          <div class="title icon">{{ isNoDateShow ? "引き渡し日未入力" : "引き渡し日入力済み" }}</div>
          <div v-if="shorten().length === 0">
            <div class="title">条件なし</div>
          </div>
          <template v-else v-for="(s, index) in shorten()">
            <div class="title" :key="'shorten-' + index">
              {{ s.key }}<div class="data"> : {{ s.value }}</div>
            </div>
          </template>
        </div>
        <div class="l-flex">
          <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
        </div>
      </div>
    </div>
    <div class="l-full-white l-area">
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{ active: !isSearchDetail }"
            @click="isSearchDetail = false; initSearch(false);"
          >簡易</div>
          <div
            class="c-tabs__each"
            :class="{ active: isSearchDetail }"
            @click="isSearchDetail = true; initSearch(false);"
          >詳細</div>
        </div>
      </div>
      <div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">廃棄物名称</span>
              <InputText
                :value.sync="searchParam.wasteName"
                inputType="text"
                placeholder="廃棄物名称を入力"
                :validation="validateMaxLen"
                :validateArg="100"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">排出事業者</span>
              <v-autocomplete ref="generate_company_autocomplete" @change="s => {onChange(s, 'generate_company_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorCompanyList" :min-len="0" :wait="300" @update-items='updateGeneratorCompanyList' v-model="selectedGeneratorCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
              </v-autocomplete>
            </div>
            <div>
              <span class="text-label">マニフェスト番号</span>
              <InputText
                :value.sync="searchParam.manifestNo"
                inputType="text"
                placeholder="マニフェスト番号を入力"
                :validation="validateNumber"
              >
              </InputText>
            </div>
            <div v-if="isNoDateShow">
              <span class="text-label"></span>
              <div class="c-inputWrap__items__cols search-wrap">
                <div class="c-inputWrap">
                  <div class="c-checkbox">
                    <input
                        id="only-empty"
                        type="checkbox"
                        v-model="searchParam.isOnlyEmptyRegist"
                    />
                    <label class="c-checkbox__label" for="only-empty">
                      <span class="c-checkbox__box"></span>
                      空登録のみ
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="text-label">引き渡し日</span>
              <div class="c-inputWrap__items__cols search-wrap">
                <InputDate
                  :value.sync="searchParam.collectionDateFrom"
                  placeholder="0000/00/00"
                />
                <span>〜</span>
                <InputDate
                  :value.sync="searchParam.collectionDateTo"
                  placeholder="0000/00/00"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <span class="text-label"></span>
          <div class="l-flex wrap full">
            <div class="c-input">
              <div class="c-checkbox">
                <input
                    :id="`isMine-is-status`"
                    type="checkbox"
                    v-model="searchParam.isMine"
                />
                <label
                    class="c-checkbox__label"
                    :for="`isMine-is-status`"
                >
                  <span class="c-checkbox__box"></span>
                  自身IDのみ
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- 詳細のときだけ表示 -->
        <template v-if="isSearchDetail">
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">CBA品目</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.cbaItemId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in cbaItems"
                    :key="`cba-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">廃棄物種類</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.jwnetItemTypeId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in jwnetItemsOpt"
                    :key="`jwnet-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">ルート番号</span>
                <InputText
                  :value.sync="searchParam.routeId"
                  inputType="number"
                  placeholder="ルート番号を入力"
                  :validation="validateNumberMaxLen"
                  :validateArg="11"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                  :value.sync="searchParam.routeName"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="50"
                >
                </InputText>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">ステータス</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(st, index) in statuses"
                    :key="index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`st-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.status"
                        :value="st.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`st-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ st.label }}
                        <span v-if="showcount(st.value) !== null">
                          {{ `(${showcount(st.value)})` }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div
                  v-for="(list, index) in searchParam.generateStoreIdList"
                  :key="`t-slect-${index}`"
                  class="c-input"
              >
                <span class="text-label">
                  <p v-if="index === 0">排出事業場</p>
                </span>
                <div class="c-inputWrap__items__cols center search-wrap">
                  <v-autocomplete ref="generate_store_autocomplete" @change="s => {onChange(s, 'generate_store_autocomplete')}" placeholder="" class="c-input" :items="selectGenerateStoreList" :min-len="0" :wait="300" @update-items='(text) => updateGenerateStoreList(text)' v-bind:initItems="generateStoreList" v-model="selectedGenerateStore[index]" :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
                  <span
                      v-if="searchParam.generateStoreIdList.length > 1 && index > 0"
                      class="c-btn-icon delete secondary small l-item-l"
                      @click="deleteGenerateStoreSelection(index)"/>
                </div>
              </div>
              <div
                  v-if="searchParam.generateStoreIdList.length < 5"
                  class="c-btn secondary sm add l-item-l"
                  @click="addGenerateStoreSelection"
              >
                排出事業場を追加
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">運搬事業者</span>
                  <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='updateGeneratorCollectList' v-model="selectedGeneratorCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分事業者</span>
                  <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分場</span>
                <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                </v-autocomplete>
              </div>
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(wt, index) in waste_type"
                    :key="index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`wt-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.wasteType"
                        :value="wt.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`wt-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ wt.label }}
                      </label>
                      <div v-if="wt.value == waste_type[0].value && searchParam.wasteType.includes(waste_type[0].value)">
                        <div class="l-flex wrap full">
                          <span>(</span>
                          <div
                            v-for="(jitf, index) in jwnet_item_type_field"
                            :key="index"
                            class="c-input"
                          >
                            <div class="c-checkbox">
                              <input
                                :id="`jitf-is-status-${index}`"
                                type="checkbox"
                                v-model="searchParam.jwnetItemTypeField"
                                :value="jitf.value"
                              />
                              <label
                                class="c-checkbox__label"
                                :for="`jitf-is-status-${index}`"
                              >
                                <span class="c-checkbox__box"></span>
                                {{ jitf.label }}
                              </label>
                            </div>
                          </div>
                          <span>)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">連絡番号</span>
                <InputText
                  :value.sync="searchParam.contactNo"
                  inputType="text"
                  placeholder="連絡番号を入力"
                  :validation="validateNumber"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">備考</span>
                <InputText
                  :value.sync="searchParam.remarks"
                  inputType="text"
                  placeholder="備考を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">ノート</span>
                <InputText
                  :value.sync="searchParam.manifestLabel"
                  inputType="text"
                  placeholder="ノートを入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label"></span>
                <div class="l-flex wrap full">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                      :id="`isOnlyEmptyRegist-is-status`"
                      type="checkbox"
                      v-model="searchParam.isActive"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`isOnlyEmptyRegist-is-status`"
                      >
                      <span class="c-checkbox__box"></span>
                        削除は含めない
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import {DOMAIN} from "@/const";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, callApi, selectionLocalStorage],
  data() {
    return {
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      autoSelect: false,
      selectedDisposalSite: '', //選択した処分場
      selectedGeneratorCompany: '', //選択した排出事業者
      selectedGenerateStore: [null], //選択した排出事業場
      isLoadingSearchGenerateStore: true, // 排出事業場を取得APIが完了するかどうか判断
      generateStoreList: [], // 排出事業場の選択肢
      selectGenerateStoreList: [], // 入力テキストで絞った排出事業場の選択肢
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      isDisplaySearch: false,
      isSearchDetail: false,
      isNoDateShow: false,
      // 検索v-model
      searchParam: {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        isMine: true,
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        generateCompanyId: "",
        generateStoreIdList: [null],
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true,
      },
      statuses: [
        {
          label: "交付期限注意",
          value: 10,
        },
        {
          label: "交付期限切れ",
          value: 11,
        }
      ],
      waste_type: [
        { value: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , label: "産業廃棄物" },
        { value: DOMAIN.WASTE_TYPE.VALUABLES , label: "有価物" },
        { value: DOMAIN.WASTE_TYPE.COMMON_WASTE , label: "一般廃棄物" },
      ],
      jwnet_item_type_field: [
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , label: "普通産業廃棄物" },
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , label: "特別管理産業廃棄物" },
      ],
    };
  },

  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
          this.$refs[ref].searchText = queryString;
      });
    },
    showcount(statusVal) {
      let count = null;
      for (const sc of this.manifestStatusCount) {
        if (sc.manifestSearchTypeId === statusVal) {
          count = sc.count;
        }
      }
      return count;
    },

    initSearch(isAll = true) {
      this.selectedGeneratorCollect = ''
      this.selectedGeneratorDisposal = ''
      this.selectedGeneratorCompany = isAll ? "" : this.selectedGeneratorCompany
      this.selectedGenerateStore = [null]
      this.selectedDisposalSite = ''
      this.searchParam = {
        wasteName: isAll ? "" : this.searchParam.wasteName,
        generateCompanyId: isAll ? "" : this.searchParam.generateCompanyId,
        manifestNo: isAll ? "" : this.searchParam.manifestNo,
        collectionDateFrom: isAll ? "" : this.searchParam.collectionDateFrom,
        collectionDateTo: isAll ? "" : this.searchParam.collectionDateTo,
        isOnlyEmptyRegist: isAll ? "" : this.searchParam.isOnlyEmptyRegist,
        isMine: isAll ? this.isNoDateShow ? false : true : this.searchParam.isMine,
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        generateStoreIdList: [null],
        generateStoreList: isAll ? [] : this.searchParam.generateStoreList,
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true,
      };
    },

    // 引き渡し日入力済み/未入力の切り替え
    switchTab() {
      this.isSearchDetail = false;
      this.isNoDateShow = !this.isNoDateShow;
      this.initSearch(true);
      this.$store.commit("searchParamManifestsReserve/setIsNoCollectionDate", this.isNoDateShow);
      this.$emit("search", this.searchParam);
    },

    // 排出事業場の項目追加
    addGenerateStoreSelection() {
      //追加した項目の選択肢の初期化
      this.selectGenerateStoreList = this.generateStoreList
      this.selectedGenerateStore.push(null);
      this.searchParam.generateStoreIdList.push(null);
    },
    // 排出事業場の項目削除
    deleteGenerateStoreSelection(index) {
      this.selectedGenerateStore.splice(index,1);
      this.searchParam.generateStoreIdList.splice(index, 1);
    },

    resetSearchHandler() {
      this.initSearch();
      // this.$emit("search", this.searchParam); 条件リセット後の自動検索をやめる
    },

    searchHandler() {
      // ページの初期化
      this.$store.commit("searchParamManifestsReserve/setManifestReservePage", 1);
      this.$emit("search", this.searchParam);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamManifestsReserve/get"];

      if (prev.wasteName) result.push({ key: "廃棄物名称", value: prev.wasteName });
      if (prev.collectCompanyId) result.push({ key: "運搬事業者", value: this.generatorCollectList.filter(c => c.id === prev.collectCompanyId)[0].name });
      if (prev.collectionDateFrom || prev.collectionDateTo) result.push({ key: "引き渡し日", value: prev.collectionDateFrom + "〜" + prev.collectionDateTo });
      if (prev.isOnlyEmptyRegist) result.push({ key: "空登録のみ", value: "" });
      if (prev.wasteType) {
        result.push({
          key: "廃棄物区分",
          value: this.waste_type
              .filter(scb => prev.wasteType .includes(scb.value))
              .map(scb => scb.label).join("、")
          });
      }
      if (prev.jwnetItemTypeField) result.push({ key: "廃棄物種類区分", value: prev.jwnetItemTypeField });
      if (prev.generateCompanyId) result.push({ key: "排出事業者", value:  this.generatorCompanyList.filter(c => c.id === prev.generateCompanyId)[0].name });
      if ((prev.generateStoreIdList && prev.generateStoreIdList[0]) || prev.generateStoreIdList.length > 1) {
        result.push({
          key: "排出事業場",
          value: this.storeList
              .flatMap(c => c.store)
              .filter(s => prev.generateStoreIdList.filter(gsId => gsId === s.id).length)
              .map(s => s.name).join("、")
        });
      }
      if (prev.disposalCompanyId) result.push({ key: "処分事業者", value: this.generatorDisposalList.filter(d => d.id === prev.disposalCompanyId)[0].name });
      if (prev.disposalSiteId) result.push({ key: "処分場", value: this.disposalSiteList.filter(c => c.id === prev.disposalSiteId)[0].name });
      if (prev.manifestNo) result.push({ key: "マニフェスト番号", value: prev.manifestNo });
      if (prev.contactNo) result.push({ key: "連絡番号", value: prev.contactNo });
      if (prev.jwnetItemTypeId) result.push({ key: "廃棄物種類", value: this.jwnetItemsOpt.filter(j => j.id === prev.jwnetItemTypeId)[0].name });
      if (prev.cbaItemId) result.push({ key: "CBA品目", value: this.cbaItems.filter(c => c.id === prev.cbaItemId)[0].name });
      if (prev.status) {
        result.push({
          key: "ステータス",
          value: this.statuses
              .filter(scb => prev.status.includes(scb.value))
              .map(scb => scb.label).join("、")
        });
      }
      if (prev.remarks) result.push({ key: "備考", value: prev.remarks });
      if (prev.manifestLabel) result.push({ key: "ノート", value: prev.manifestLabel });
      if (prev.isMine) result.push({ key: "自身IDのみ", value: prev.isMine });
      if (prev.isActive) result.push({ key: "削除を含めない", value: prev.isActive });
      return result;
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGenerateStoreList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業場を表示する
        this.selectGenerateStoreList = this.generateStoreList
        return
      }
      this.selectGenerateStoreList = this.generateStoreList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業場を表示する
        this.selectDisposalSiteList = this.disposalSiteList
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業者を表示する
        this.selectGeneratorCompanyList = this.generatorCompanyList
        return
      }
      this.selectGeneratorCompanyList = this.generatorCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
  },
  watch: {
    selectedGeneratorCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.searchParam.disposalCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorCompany: function(newVal) {
      this.searchParam.generateCompanyId = newVal ? newVal.id : ''
    },
    selectedGenerateStore: function(newVal) {
      if (!this.isLoadingSearchGenerateStore) {
        newVal.forEach( (e, i) => {
          this.searchParam.generateStoreIdList[i] = e ? e.id : null
        })
      }
    },
    selectedDisposalSite: function(newVal) {
      this.searchParam.disposalSiteId = newVal ? newVal.id : ''
    }
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamManifestsReserve/get"];
    this.isNoDateShow = this.$store.getters["searchParamManifestsReserve/isNoDateShow"];
    this.isSearchDetail = this.$store.getters["searchParamManifestsReserve/isSearchDetail"];
    window.addEventListener("scroll", this.handleScroll);

    this.getManifestCountApi();
    this.getSelectoptionGenerateStoreForGenerate();
    this.getGeneratorCollectApi();
    this.getJwnetItemsApi();
    this.getGeneratorDisposalApi();
    this.getCbaItemsApi();
    this.getGeneratorCompanyApiForGenerate();
    this.getDisposalSiteApi();
  },
};
</script>
